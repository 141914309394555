import entersViewport from './entersViewport';

export default class CategorySelect {
  constructor({
    resultsContainer,
    categoriesBar,
    categoriesMobileBar,
    placeholder,
    handleCategorySelectFn,
    loadMore = true,
  }) {
    let currentPage = 0;
    let isLoading = false;
    let isLoadingCompleted = false;
    let currentCategory = "";


    let categoriesItems = [];
    if (categoriesBar) {
      currentCategory = categoriesBar.querySelector(
        '.nav__link.active[data-value]'
      )
        ? categoriesBar
          .querySelector('.nav__link.active[data-value]')
          .getAttribute('data-value')
        : undefined;

      categoriesItems = categoriesBar.querySelectorAll(
        '.nav__link[data-value]'
      );
      if (categoriesMobileBar)
        categoriesItems = [
          ...categoriesItems,
          ...categoriesMobileBar.querySelectorAll('.nav__link[data-value]'),
        ];
      for (const item of categoriesItems) {
        item.addEventListener('click', () => {
          isLoading = true;
          isLoadingCompleted = false;
          currentPage = 0;
          resultsContainer.style.display = 'none';
          resultsContainer.innerHTML = '';
          placeholder.style.display = 'flex';
          let activeItems = [
            ...categoriesBar.querySelectorAll('.nav__link.active'),
          ];
          if (categoriesMobileBar)
            activeItems = [
              ...activeItems,
              ...categoriesMobileBar.querySelectorAll('.nav__link.active'),
            ];
          for (const activeItem of activeItems) {
            activeItem.classList.remove('active');
          }

          currentCategory = item.getAttribute('data-value');

          let newActiveItems = [
            ...categoriesBar.querySelectorAll(
              `.nav__link[data-value="${currentCategory}"]`
            ),
          ];

          if (categoriesMobileBar) {
            newActiveItems = [
              ...newActiveItems,
              ...categoriesMobileBar.querySelectorAll(
                `.nav__link[data-value="${currentCategory}"]`
              ),
            ];
          }

          for (const newActiveItem of newActiveItems) {
            newActiveItem.classList.add('active');

            let dropdownLink = newActiveItem.closest('.nav__item.dropdown');
            if (dropdownLink) {
              dropdownLink = dropdownLink.querySelector('.dropdown__link');
              dropdownLink.classList.add('active');
              dropdownLink.querySelector(
                '.categoriesSelect__dropdownText'
              ).textContent = currentCategory;
            }
          }

          setTimeout(function () {
            handleCategorySelectFn(currentCategory, currentPage);
            if (!loadMore) placeholder.style.display = 'none';
            resultsContainer.style.display = 'flex';
            isLoading = false;
          }, 300);
        });
      }
    }



    if (loadMore) {
      document.addEventListener(
        'scroll',
        () => {
          if (
            !isLoading &&
            !isLoadingCompleted &&
            entersViewport(placeholder)
          ) {
            isLoading = true;
            currentPage++;
            const result = handleCategorySelectFn(currentCategory, currentPage);
            if (!result) {
              isLoadingCompleted = true;
              placeholder.style.display = 'none';
            }
            isLoading = false;
          }
        },
        { passive: true }
      );
    }
  }
}
